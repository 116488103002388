.react-tel-input {
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin-top: 0;
  margin-bottom: 8px;
  color: black;
  .form-control {
    padding: 15px 14px 15px 58px;
  }
}
